import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import {
  amber,
  blue,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  teal,
} from "@mui/material/colors";
import Header from "./Components/Header";
import Features from "./Components/Features";
import Kitchen from "./Components/kitchen";
import Examples from "./Components/Examples";
import HowToUse from "./Components/HowToUse";
import FAQ from "./Components/FAQ";
import Footer from "./Components/footer";
import Games from "./Components/Games";
import Navbar from "./Components/Navbar";
import Meanings from "./Components/Meanings";

// 🌈
const colors = [
  amber,
  blue,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  teal,
];

const theme = createTheme({
  palette: {
    primary: colors[Math.floor(Math.random() * colors.length)],
  },
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            minHeight: "100dvh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Features />
                  <Box
                    sx={{
                      flex: 1,
                      position: "relative",
                      mt: { xs: 4, md: 10 },
                      mb: { xs: 4, md: 6 },
                    }}
                  >
                    <Kitchen />
                  </Box>
                  <Examples />
                  <HowToUse />
                  <FAQ />
                  <Footer />
                </>
              }
            />
            <Route path="/games" element={<Games />} />
            <Route path="/meanings" element={<Meanings />} />
          </Routes>
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
