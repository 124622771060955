import { Box, Container, Grid, Typography } from "@mui/material";
import { FeatureCard } from "./FeatureCard";

export default function Features() {
  return (
    <Box
      component="section"
      sx={{
        py: { xs: 4, md: 8 },
        bgcolor: "background.paper",
        borderTop: "1px solid",
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "1.75rem", md: "2.25rem" },
            mb: { xs: 3, md: 6 },
            textAlign: "center",
            fontWeight: "bold",
            background: "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          絵文字ミックスの特徴
        </Typography>

        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          justifyContent="center"
          sx={{ mb: { xs: 6, md: 8 } }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <FeatureCard
              icon="🎨"
              title="10万以上の組み合わせ"
              description="ユニークな絵文字の組み合わせを作成できます。無限の可能性が広がります。"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FeatureCard
              icon="💾"
              title="簡単保存"
              description="作成した絵文字は画像として保存可能。SNSやメッセージで共有できます。"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FeatureCard
              icon="🎲"
              title="ランダム機能"
              description="インスピレーションが必要な時は、ランダム機能で意外な組み合わせを発見できます。"
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            borderTop: "1px solid",
            borderColor: "divider",
            pt: { xs: 6, md: 8 }, // 增加上边距
            mt: { xs: 4, md: 6 }, // 增加与上方内容的间距
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "1.5rem", md: "2rem" },
              mb: { xs: 3, md: 4 },
              textAlign: "center",
              fontWeight: "bold",
              background: "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            使い方ビデオ
          </Typography>

          <Box
            sx={{
              position: "relative",
              width: "100%",
              maxWidth: "800px",
              mx: "auto",
              paddingTop: "56.25%",
              borderRadius: 2,
              overflow: "hidden",
              boxShadow:
                "0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06)",
            }}
          >
            <iframe
              src="https://www.youtube.com/embed/hA3M6tW8JPY"
              title="絵文字ミックス ゲームの使い方"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
